import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import Logo from "../assets/icon/springtennis_logo.svg";
import ArrowRight from "../assets/icon/ic_arrow_right.svg";
import ArrowBi from "../assets/icon/ic_arrow_bi.svg";
import ChartIncreasing from "../assets/icon/ic_chart_increasing.svg";
import Book from "../assets/icon/ic_book.svg";

import OcsAndApp from "../assets/image/ocs_and_app.svg";
import Ocs from "../assets/image/ocs.svg";
import MobileApp from "../assets/image/mobile_app.svg";
import Crm from "../assets/image/crm.svg";
import Cam1 from "../assets/image/cam1.png";
import Cam2 from "../assets/image/cam2.png";
import { toast } from "react-toastify";
import { Widget } from "@typeform/embed-react";

const Page = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: blue; */
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
`;

const Navbar = styled.div<{ colored?: boolean }>`
  position: fixed !important;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80px;
  padding: 0 20px;
  z-index: 100;
  transition-duration: 0.5s;
  background-color: #ffffff;
  ${({ colored }) => {
    if (colored) {
      return css`
        background-color: rgba(255, 255, 255, 0.24);
        backdrop-filter: blur(12px);
      `;
    }
  }};
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const NavbarContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
`;

const MenuList = styled.div`
  position: absolute;
  right: 40px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 512px;
  /* justify-content: space-around; */
`;

const Menu = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0px;
  padding: 16px;
  margin-left: 8px;
  text-align: center;
  cursor: pointer;
  color: #667380;
  transition-duration: 0.5s;
  &:hover,
  &:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
    color: #000000;
  }
`;

const FilledButton = styled.button`
  padding: 12px;
  border-radius: 4px;
  background-color: #03ad8b;
  cursor: pointer;
  color: #ffffff;
  border: none;

  font-family: Pretendard-SemiBold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #009174;
  }
`;

const Section1Title = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: left;
  color: #141b21;
  margin-bottom: 24px;
`;

const Section1Text = styled.span`
  font-family: Pretendard;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #46525e;
  margin-bottom: 40px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ColBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
  border-radius: 24px;
  background-color: #ecf9f6;
  margin: 24px;
`;

const CardTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  margin-bottom: 24px;
`;

const CardSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #46525e;
  margin-bottom: 32px;
`;

const CardText = styled.li`
  font-family: Pretendard-SemiBold;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
  color: #667380;
  margin-bottom: 4px;
`;

const ProductTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
  color: #0b9975;
  margin-bottom: 40px;
`;

const ProductSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #141b21;
  margin-bottom: 8px;
`;

// const ProductCaption = styled.span`
//   font-family: Pretendard-Medium;
//   font-size: 16px;
//   line-height: 28px;
//   letter-spacing: 0px;
//   text-align: center;
//   color: #667380;
// `;

const SegmentedControl = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  width: 80%;
  border-radius: 12px;
  background-color: #ebeff2;
  padding: 8px;
`;

const SegmentedControlItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 12px;
  min-width: 120px;
  font-family: Pretendard-Medium;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: #bbc2c9;
  transition-duration: 0.5s;
  &:hover {
    cursor: pointer;
  }
  ${({ active }) => {
    if (active) {
      return css`
        box-shadow: 1px 1px 8px 0px #00000014;
        border-radius: 10px;
        color: #303b45;
        background-color: #ffffff;
      `;
    }
  }}
`;

const SegmentedItemTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #141b21;
  margin-bottom: 8px;
`;

const SegmentedItemSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #46525e;
`;

const SegmentedItemListText = styled.li`
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #667380;
`;

const SegmentedItemText = styled.span`
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #667380;
`;

const ExperienceTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #141b21;
  margin-bottom: 8px;
`;

const ExperienceSubTitle = styled.span`
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #46525e;
  margin-bottom: 48px;
`;

const OnlineOfflineRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 952px;
  width: 100%;
  border-bottom: 4px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(180, 230, 255, 0) 0%,
    #5ed4dc 20.83%,
    #4ac5a6 89.58%,
    rgba(200, 255, 242, 0) 100%
  );
  border-image-slice: 1;
`;

const OnlineOfflineText = styled.span`
  flex: 1;
  padding: 8px;
  font-family: Pretendard-Bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #04b2bd;
`;

const OfflineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const OnlineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Circle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 178px;
  height: 178px;
  border-radius: 178px;
  border: 3px solid #ebeff2;
  background-color: #fafbfc;
`;

const CircleTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #080d0e;
  margin-bottom: 16px;
  margin-top: 44px;
`;

const CircleText = styled.span`
  font-family: Pretendard-Regular;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
`;

const TypeformCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 80px 0;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 40px;
`;

const TypeformWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 972px;
  height: 400px;
`;

const TypeformTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 44px;
  line-height: 56px;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 24px;
`;

const TypeformSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  color: #667380;
  margin-bottom: 48px;
`;

const FooterRow = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: auto;
`;

const FooterTitle = styled.span`
  font-family: Pretendard;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #323334;
`;

const FooterContent = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b4d4e;
`;

const Caption = styled.span`
  font-family: Pretendard;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #646768;
`;

export const Test2 = () => {
  const [prevScrollY, setPrevScrollY] = useState<number>(0);
  const [sectionY, setSectionY] = useState<any>({
    section1: 0,
    section2: 0,
    section3: 0,
    section4: 0,
  });
  const [showNavbar, setShowNavbar] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const scrollHandler = () => {
    if (window.scrollY > prevScrollY && window.scrollY > 80) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setPrevScrollY(window.scrollY);
  };

  const resizeHandler = () => {
    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, false);
    window.addEventListener("scroll", scrollHandler, false);

    if (window.scrollY === 0) {
      const section1 = document.getElementById("section1");
      const section2 = document.getElementById("section2");
      const section3 = document.getElementById("section3");
      const section4 = document.getElementById("section4");
      setSectionY({
        section1: section1?.getBoundingClientRect()?.top,
        section2: section2?.getBoundingClientRect()?.top,
        section3: section3?.getBoundingClientRect()?.top,
        section4: section4?.getBoundingClientRect()?.top,
      });
    }

    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, prevScrollY]);

  return (
    <Page>
      <Navbar
        style={{
          display: showNavbar ? "flex" : "none",
          height: isMobile ? 64 : undefined,
        }}
      >
        <NavbarContainer>
          <LogoWrapper>
            {isMobile ? (
              <img
                src={Logo}
                alt="logo"
                style={{ height: 22, objectFit: "cover" }}
              />
            ) : (
              <img src={Logo} alt="logo" />
            )}
          </LogoWrapper>
          {!isMobile ? (
            <MenuList>
              <Menu
                onClick={() =>
                  window.scrollTo({
                    top: sectionY?.section2,
                    behavior: "smooth",
                  })
                }
              >
                제품 소개
              </Menu>
              <Menu
                onClick={() =>
                  window.scrollTo({
                    top: sectionY?.section3,
                    behavior: "smooth",
                  })
                }
              >
                이용 안내
              </Menu>
              <Menu
                onClick={() =>
                  window.scrollTo({
                    top: sectionY?.section4,
                    behavior: "smooth",
                  })
                }
              >
                도입 문의
              </Menu>
              <FilledButton
                style={{ marginLeft: 24 }}
                onClick={() => {
                  toast("준비중입니다", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                    closeOnClick: true,
                    type: "warning",
                    draggable: true,
                    progress: undefined,
                  });
                }}
              >
                데모 체험하기
              </FilledButton>
            </MenuList>
          ) : null}
        </NavbarContainer>
      </Navbar>
      <Section
        id="section1"
        style={{
          marginTop: isMobile ? 64 : 80,
          backgroundColor: "#f0f3f5",
          paddingTop: isMobile ? 32 : 64,
          paddingBottom: isMobile ? 32 : 64,
        }}
      >
        <ContentContainer>
          <RowBox
            style={{
              width: "100%",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <ColBox
              style={{
                width: isMobile ? "100%" : undefined,
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <Section1Title
                style={{
                  textAlign: isMobile ? "center" : "left",
                  fontSize: isMobile ? 32 : 40,
                }}
              >
                테스트2: 테니스를{" "}
                <Section1Title
                  style={{ color: "#03ad8b", fontSize: isMobile ? 32 : 40 }}
                >
                  똑똑
                </Section1Title>
                하게 배우는
                <br />
                세상을 만들어나갑니다.
              </Section1Title>
              <Section1Text style={{ fontSize: isMobile ? 16 : 20 }}>
                AI 기반 테니스 코칭솔루션{" "}
                <Section1Text
                  style={{ color: "#03ad8b", fontSize: isMobile ? 16 : 20 }}
                >
                  스프링테니스
                </Section1Text>
              </Section1Text>
              <FilledButton
                style={{
                  padding: 16,
                  width: 160,
                  fontSize: 16,
                  marginBottom: 24,
                }}
                onClick={() =>
                  window.scrollTo({
                    top: sectionY?.section4,
                    behavior: "smooth",
                  })
                }
              >
                도입 문의하기
              </FilledButton>
            </ColBox>
            <div style={{ minWidth: "15%" }} />
            <img
              src={OcsAndApp}
              style={{ maxWidth: 680, width: isMobile ? "80%" : "100%" }}
              alt="ocs_and_app"
            />
          </RowBox>
        </ContentContainer>
      </Section>
      <Section
        id="section2"
        style={{
          marginTop: isMobile ? 56 : 96,
          marginBottom: isMobile ? 56 : 96,
        }}
      >
        <ContentContainer>
          <RowBox
            style={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <img
                src={ChartIncreasing}
                alt="chartIncreasing"
                style={{ width: 64, marginBottom: 32 }}
              />
              <CardTitle>
                코칭 퀄리티를
                <br />
                업그레이드 해보세요.
              </CardTitle>
              <CardSubTitle>
                데이터 기반 체계적 코칭으로 수강생 만족도 UP!
              </CardSubTitle>
              <ul style={{ paddingLeft: 16 }}>
                <CardText>
                  신체 각도, 구속, 스핀량 등에 기반한
                  <br />
                  전문적 레슨 콘텐츠를 만들어보세요
                </CardText>
                <CardText>
                  수업 중 화면으로 자세를 비교하며 폼을 교정해보세요
                </CardText>
              </ul>
            </Card>
            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <img
                src={Book}
                alt="book"
                style={{ width: 64, marginBottom: 32 }}
              />
              <CardTitle>
                레슨 피드백 노트가
                <br />매 수업 제공됩니다.
              </CardTitle>
              <CardSubTitle>
                정량적 데이터를 기반으로 실력을 분석해드려요.
              </CardSubTitle>
              <ul style={{ paddingLeft: 16 }}>
                <CardText>
                  자주 포착되는 문제점을 분석하여 AI 자세 피드백을 제공
                </CardText>
                <CardText>나의 실력 변화추이를 대시보드 형태로 확인</CardText>
                <CardText>
                  나의 스윙 영상을 다시 보며 수업을 복습해보세요
                </CardText>
              </ul>
            </Card>
          </RowBox>
        </ContentContainer>
      </Section>
      <Section id="section3">
        <ContentContainer>
          <ProductTitle
            style={isMobile ? { fontSize: 32, marginBottom: 20 } : {}}
          >
            스프링테니스 솔루션 패키지
          </ProductTitle>
          <ProductSubTitle
            style={
              isMobile
                ? { fontSize: 16, marginBottom: 40 }
                : { marginBottom: 80 }
            }
          >
            카메라 4대 + 코칭 솔루션 + 어플리케이션 + 관리자페이지로 구성
          </ProductSubTitle>
          {/* <ProductCaption
            style={
              isMobile
                ? { fontSize: 12, marginBottom: 40 }
                : { marginBottom: 80 }
            }
          >
            20만원 / 월 (최소 12개월 정기구독 기준)
          </ProductCaption> */}
          <SegmentedControl>
            <RowBox style={{ flex: 1 }}>
              <SegmentedControlItem
                active={activeTab === 0}
                onClick={() => setActiveTab(0)}
                style={isMobile ? { fontSize: 14, padding: 8 } : {}}
              >
                카메라
              </SegmentedControlItem>
              <SegmentedControlItem
                active={activeTab === 1}
                onClick={() => setActiveTab(1)}
                style={isMobile ? { fontSize: 14, padding: 8 } : {}}
              >
                현장 코칭 시스템
              </SegmentedControlItem>
            </RowBox>
            <RowBox style={{ flex: 1 }}>
              <SegmentedControlItem
                active={activeTab === 2}
                onClick={() => setActiveTab(2)}
                style={isMobile ? { fontSize: 14, padding: 8 } : {}}
              >
                어플리케이션
              </SegmentedControlItem>
              <SegmentedControlItem
                active={activeTab === 3}
                onClick={() => setActiveTab(3)}
                style={isMobile ? { fontSize: 14, padding: 8 } : {}}
              >
                관리자 페이지
              </SegmentedControlItem>
            </RowBox>
          </SegmentedControl>
          {/* camera */}
          <ColBox
            style={{
              display: activeTab === 0 ? "flex" : "none",
              paddingTop: 56,
              maxWidth: 820,
              width: "100%",
            }}
          >
            <RowBox
              style={{
                width: "100%",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "space-between",
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "wrap",
                marginBottom: 24,
              }}
            >
              <img
                src={Cam1}
                alt="camera_1"
                style={{
                  borderRadius: 16,
                  width: 320,
                  height: 180,
                  background: "#F5F7FA",
                }}
              />
              <ColBox style={{ minWidth: 300 }}>
                <SegmentedItemTitle style={{ fontSize: isMobile ? 24 : 28 }}>
                  자세 촬영용 초고속 카메라
                </SegmentedItemTitle>
                <SegmentedItemText>기본 4대</SegmentedItemText>
                <SegmentedItemText>해상도 728 x 544 0.4M</SegmentedItemText>
                <SegmentedItemText>최대 프레임 레이트 282fps</SegmentedItemText>
                <SegmentedItemText>컬러 이미지 센서</SegmentedItemText>
                <SegmentedItemText>
                  위치 조정이 가능한 이동형 카메라
                </SegmentedItemText>
              </ColBox>
            </RowBox>
            <RowBox
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: isMobile ? "center" : "space-between",
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "wrap",
              }}
            >
              <img
                src={Cam2}
                alt="camera_2"
                style={{
                  borderRadius: 16,
                  width: 320,
                  height: 180,
                  background: "#F5F7FA",
                }}
              />
              <ColBox style={{ minWidth: 300 }}>
                <SegmentedItemTitle style={{ fontSize: isMobile ? 24 : 28 }}>
                  볼 트래킹 초고속 카메라
                </SegmentedItemTitle>
                <SegmentedItemText>기본 1대 (천장 고정 형태)</SegmentedItemText>
                <SegmentedItemText>해상도 720 x 540 0.4M</SegmentedItemText>
                <SegmentedItemText>최대 프레임 레이트 527fps</SegmentedItemText>
                <SegmentedItemText>흑백 이미지 센서</SegmentedItemText>
              </ColBox>
            </RowBox>
          </ColBox>
          {/* ocs */}
          <RowBox
            style={{
              display: activeTab === 1 ? "flex" : "none",
              paddingTop: 56,
              maxWidth: 900,
              width: "100%",
              alignItems: isMobile ? "center" : "flex-start",
              justifyContent: isMobile ? "center" : "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <img
              src={Ocs}
              style={{
                maxWidth: 680,
                width: isMobile ? "80%" : "55%",
              }}
              alt="ocs"
            />
            <ColBox style={{ marginTop: 20 }}>
              <SegmentedItemTitle>현장 코칭 시스템</SegmentedItemTitle>
              <SegmentedItemSubTitle>
                코칭을 위한 전용 코칭툴
              </SegmentedItemSubTitle>
              <ul style={{ paddingLeft: 16 }}>
                <SegmentedItemListText>
                  포핸드, 백핸드, 서브, 발리 구분하여 샷 단위 추출
                </SegmentedItemListText>
                <SegmentedItemListText>
                  코치와 수강생 간 자세 비교
                </SegmentedItemListText>
                <SegmentedItemListText>
                  코칭에 필요한 선/도형 그리기
                </SegmentedItemListText>
                <SegmentedItemListText>
                  슬로우모션 모드로 정밀한 비교 가능
                </SegmentedItemListText>
              </ul>
            </ColBox>
          </RowBox>
          {/* mobile app */}
          <RowBox
            style={{
              display: activeTab === 2 ? "flex" : "none",
              paddingTop: 56,
              maxWidth: 900,
              width: "100%",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <img
              src={MobileApp}
              style={{
                maxWidth: 680,
                width: isMobile ? "80%" : "55%",
              }}
              alt="mobileApp"
            />
            <ColBox style={isMobile ? {} : { marginBottom: 80 }}>
              <SegmentedItemTitle>어플리케이션</SegmentedItemTitle>
              <SegmentedItemSubTitle>
                인사이트 모아보기, 일정 관리를 한 번에
              </SegmentedItemSubTitle>
              <ul style={{ paddingLeft: 16 }}>
                <SegmentedItemListText>
                  레슨 영상 다시보기
                </SegmentedItemListText>
                <SegmentedItemListText>AI 자세 피드백</SegmentedItemListText>
                <SegmentedItemListText>
                  레슨 피드백 데이터 조회
                </SegmentedItemListText>
                <SegmentedItemListText>
                  레슨 일정 조회 및 변경
                </SegmentedItemListText>
              </ul>
            </ColBox>
          </RowBox>
          {/* crm */}
          <RowBox
            style={{
              display: activeTab === 3 ? "flex" : "none",
              paddingTop: 56,
              maxWidth: 900,
              width: "100%",
              alignItems: isMobile ? "center" : "flex-start",
              justifyContent: isMobile ? "center" : "space-between",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <img
              src={Crm}
              style={{
                maxWidth: 680,
                width: isMobile ? "80%" : "55%",
              }}
              alt="crm"
            />
            <ColBox style={{ marginTop: 20 }}>
              <SegmentedItemTitle>관리자 페이지</SegmentedItemTitle>
              <SegmentedItemSubTitle>
                지점, 코치, 수강생 원스탑 관리 솔루션
              </SegmentedItemSubTitle>
              <ul style={{ paddingLeft: 16 }}>
                <SegmentedItemListText>
                  지점 매출 통계 조회
                </SegmentedItemListText>
                <SegmentedItemListText>코치별 정산내역</SegmentedItemListText>
                <SegmentedItemListText>
                  코치별 레슨 스케줄
                </SegmentedItemListText>
                <SegmentedItemListText>대기 명단 관리</SegmentedItemListText>
              </ul>
            </ColBox>
          </RowBox>
          {/* experience */}
          <ExperienceTitle
            style={
              isMobile ? { marginTop: 80, fontSize: 20 } : { marginTop: 156 }
            }
          >
            오프라인과 온라인을 아우르는 테니스 경험
          </ExperienceTitle>
          <ExperienceSubTitle style={isMobile ? { fontSize: 13 } : {}}>
            스프링테니스가 제공하는 편하고 강력한 기술 경험을 느껴보세요
          </ExperienceSubTitle>
          <OnlineOfflineRow>
            <OnlineOfflineText>OFFLINE</OnlineOfflineText>
            <OnlineOfflineText>ONLINE</OnlineOfflineText>
          </OnlineOfflineRow>
          <RowBox
            style={{
              paddingBottom: isMobile ? 80 : 160,
              marginTop: 40,
              flexWrap: "wrap",
              maxWidth: 952,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {isMobile ? (
              <>
                <OfflineWrapper>
                  <Circle style={{ marginBottom: 16 }}>
                    <CircleTitle>카메라</CircleTitle>
                    <CircleText>
                      자세 촬영용 카메라
                      <br />볼 트래킹 카메라
                    </CircleText>
                  </Circle>
                  <Circle>
                    <CircleTitle>코칭 솔루션</CircleTitle>
                    <CircleText>
                      샷 단위 레슨 추출
                      <br />
                      현장 다시보기
                    </CircleText>
                  </Circle>
                </OfflineWrapper>
                <OnlineWrapper>
                  <Circle style={{ marginBottom: 16 }}>
                    <CircleTitle>어플리케이션</CircleTitle>
                    <CircleText>
                      레슨 피드백 데이터
                      <br />
                      레슨 일정 조정
                    </CircleText>
                  </Circle>
                  <Circle>
                    <CircleTitle style={{ marginBottom: 8 }}>
                      관리자 페이지
                    </CircleTitle>
                    <CircleText>
                      지점 매출관리
                      <br />
                      코치 정산관리
                      <br />
                      스케줄 캘린더
                    </CircleText>
                  </Circle>
                </OnlineWrapper>
              </>
            ) : (
              <>
                <Circle>
                  <CircleTitle>카메라</CircleTitle>
                  <CircleText>
                    자세 촬영용 카메라
                    <br />볼 트래킹 카메라
                  </CircleText>
                </Circle>
                <img src={ArrowRight} alt="rightArrow" />
                <Circle>
                  <CircleTitle>코칭 솔루션</CircleTitle>
                  <CircleText>
                    샷 단위 레슨 추출
                    <br />
                    현장 다시보기
                  </CircleText>
                </Circle>
                <img src={ArrowRight} alt="rightArrow" />
                <Circle>
                  <CircleTitle>어플리케이션</CircleTitle>
                  <CircleText>
                    레슨 피드백 데이터
                    <br />
                    레슨 일정 조정
                  </CircleText>
                </Circle>
                <img src={ArrowBi} alt="bidirectionArrow" />
                <Circle>
                  <CircleTitle style={{ marginBottom: 8 }}>
                    관리자 페이지
                  </CircleTitle>
                  <CircleText>
                    지점 매출관리
                    <br />
                    코치 정산관리
                    <br />
                    스케줄 캘린더
                  </CircleText>
                </Circle>
              </>
            )}
          </RowBox>
        </ContentContainer>
      </Section>
      <Section
        id="section4"
        style={{
          backgroundColor: "#f0f3f5",
          paddingTop: isMobile ? 32 : 64,
          paddingBottom: isMobile ? 32 : 64,
        }}
      >
        <ContentContainer>
          <TypeformCard>
            <TypeformTitle
              style={isMobile ? { fontSize: 32, lineHeight: 1 } : {}}
            >
              <TypeformTitle
                style={{
                  color: "#03ad8b",
                  fontSize: isMobile ? 32 : undefined,
                }}
              >
                스프링테니스 관련 문의
              </TypeformTitle>
              를<br />
              자유롭게 남겨주세요
            </TypeformTitle>
            <TypeformSubTitle
              style={isMobile ? { fontSize: 16, marginBottom: 0 } : {}}
            >
              어떠한 문의든 최대한 빠르게 회신드리겠습니다
            </TypeformSubTitle>
            <TypeformWrapper
              data-tf-widget="Zz4QlSbi"
              data-tf-disable-auto-focus
            >
              <Widget
                medium="inline"
                id={"Zz4QlSbi"}
                style={{
                  border: "none",
                  width: "100%",
                  maxWidth: 972,
                  height: 400,
                }}
                disableScroll
                iframeProps={{ id: "typeform" }}
                inlineOnMobile={true}
              />
            </TypeformWrapper>
          </TypeformCard>
        </ContentContainer>
      </Section>
      {/* footer */}
      <Section style={{ backgroundColor: "#e7e7e7" }}>
        <ContentContainer style={{ paddingTop: 64, paddingBottom: 96 }}>
          <FooterRow>
            <FooterTitle>(주) 테니스베이</FooterTitle>
          </FooterRow>
          <FooterRow style={{ marginTop: 16 }}>
            <FooterContent>
              대표자 조기덕 | 사업자등록번호 451-88-02655
            </FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>TEL 070-7537-3135</FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>MAIL contact@springtennis.co</FooterContent>
          </FooterRow>
          <FooterRow style={{ marginTop: 32 }}>
            <ColBox>
              <Caption style={{ marginTop: 8 }}>
                Copyright ⓒ Tennisbay, co., ltd. All Rights Reserved
              </Caption>
            </ColBox>
          </FooterRow>
        </ContentContainer>
      </Section>
    </Page>
  );
};
