import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ko';

const API_URL = process.env.REACT_APP_API_URL || 'https://tennisbay.springtennis.co';
//const API_URL = "http://localhost:3030"

export const getDaySlots = async () => {
  try {
    const result = await axios.get(
       `${API_URL}/api/temp/slots`,
    );
    console.log("result:",result);

    return {
      offDays: result?.data?.offDays,
      days: result?.data?.days
    };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const makeReservation = async ( name:string, phone:string, pickedDay:number, pickedTime:string) => {
    try {
      const result = await axios.post(
         `${API_URL}/api/temp/reserve`, {name, phone, day: pickedDay, time: pickedTime}
      );
      return result?.data;
    } catch (error) {
      console.log(error);
      return { error: error };
    }
  };

  export const createLog = async (log: any) => {
    try {
      const result = await axios.post(`${API_URL}/api/temp/log`, {
        log
      });
      return result?.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  export const createLogNew = async (log: any) => {
    try {
      const option:any = {
        method: 'POST',
        body: {log},
      };

      let result: any = await fetch(`${API_URL}/api/temp/log`, option);
      return result||{};
    } catch (error) {
      console.error(error);
      return error;
    }
};