import { createGlobalStyle } from "styled-components";
import PretendardBold from "../fonts/Pretendard-Bold.woff2";
import PretendardMedium from "../fonts/Pretendard-Medium.woff2";
import PretendardRegular from "../fonts/Pretendard-Regular.woff2";
import PretendardSemiBold from "../fonts/Pretendard-SemiBold.woff2";

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Pretendard-Bold';
        src: local('Pretendard-Bold'), url(${PretendardBold}) format('woff2');
    }
    @font-face {
        font-family: 'Pretendard-Medium';
        src: local('Pretendard-Medium'), url(${PretendardMedium}) format('woff2');
    }
    @font-face {
        font-family: 'Pretendard-Regular';
        src: local('Pretendard-Regular'), url(${PretendardRegular}) format('woff2');
    }
    @font-face {
        font-family: 'Pretendard-SemiBold';
        src: local('Pretendard-SemiBold'), url(${PretendardSemiBold}) format('woff2');
    }
`;
