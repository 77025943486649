import { useEffect, useState, useRef } from "react";
import { useNavigate} from "react-router-dom";
import styled, { css } from "styled-components";
import { getDaySlots, makeReservation, createLog } from "../apis";

import { toast } from "react-toastify";
import { Widget } from "@typeform/embed-react";
import "./Reserve.css";

import IconPlace from "../assets/icon/place.svg";
import IconWallet from "../assets/icon/wallet.svg";
import Swal from "sweetalert2";

const Page = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: blue; */
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
`;

const FilledButton = styled.button`
  padding: 12px;
  border-radius: 4px;
  background-color: #03ad8b;
  cursor: pointer;
  color: #ffffff;
  border: none;

  font-family: Pretendard-SemiBold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #009174;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ColBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
  border-radius: 24px;
  background-color: #ecf9f6;
  margin: 24px;
`;

const SegmentedItemTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #141b21;
  margin-bottom: 8px;
`;

const SegmentedItemText = styled.span`
  font-family: Pretendard-Regular;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #667380;
`;

const FooterRow = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: auto;
`;

const FooterTitle = styled.span`
  font-family: Pretendard;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #323334;
`;

const FooterContent = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b4d4e;
`;

const Caption = styled.span`
  font-family: Pretendard;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #646768;
`;

const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const Reserve = () => {

// const [isMobile, setIsMobile] = useState<boolean>(true);
const [isMobile, setIsMobile] = [true, (x:any)=>{}];

  const [hash, setHash] = useState('');
  const [pickedDay, setPickedDay] = useState(0);
  const [slots, setSlots] = useState<any>([]);
  const [pickedTime, setPickedTime] = useState("");

  const [days, setDays] = useState<any>([]);
  const [offDays, setOffDays] = useState<any>([]);
 
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const debouncedNameValue = useDebounce(name, 2000);
  const debouncedPhoneValue = useDebounce(phone, 2000);

  const navigate = useNavigate();

  const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  const month = 4; // May (0 indexed)
  const year = 2023;

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();

  const calendarRows = [];
  let day = 1;

  const offSlots = [1, 2, 3, 4];
  const clickDay = async (day: any) => {
    setPickedDay(day);
    const _day = days.find((d:any)=>(d.day==day));
    setSlots(_day.availables||[]);
    setPickedTime('');

    const logObj = {hash, day}
    await createLog({event:`trainingClickDay`, log: JSON.stringify(logObj)});
  };

  for (
    let row = 0;
    row < Math.ceil((daysInMonth + firstDayOfMonth) / 7);
    row++
  ) {
    const rowData = [];

    for (let col = 0; col < 7; col++) {
      if (row === 0 && col < firstDayOfMonth) {
        rowData.push(null);
      } else if (day > daysInMonth) {
        rowData.push(null);
      } else {
        rowData.push(day);
        day++;
      }
    }
    calendarRows.push(rowData);
  }

  const resizeHandler = () => {
    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  useEffect( () => {
    window.addEventListener("resize", resizeHandler, false);
    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    (async ()=>{
      const result = await getDaySlots();
      setOffDays(result?.offDays);
      setDays(result?.days);
    })();

    window.scrollTo({top:0});

    console.log('hash11', window.location);
    const _hash = window?.location?.search?.split('=')?.[1];
    if (_hash)
      setHash(_hash);
  }, []);

  useEffect( () => {
    if (debouncedNameValue) {
      console.log('[event] name changed!');
      createLog({event:`trainingNameChange`, log: JSON.stringify({hash, name})});
    }
  }, [debouncedNameValue]);

  useEffect(() => {
    if (debouncedPhoneValue) {
      console.log('[event] phone changed!');
      createLog({event:`trainingPhoneChange`, log: JSON.stringify({hash, phone})});
    }
  }, [debouncedPhoneValue]);

  return (
    <Page>
      <Section
        id="section1"
        style={{
          marginTop: isMobile ? 32 : 40,
          backgroundColor: "#f0f3f5",
          // paddingTop: isMobile ? 0 : 64,
          // paddingBottom: isMobile ? 32 : 64,
        }}
      >
        <ContentContainer>
          <RowBox
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: isMobile ? "center" : "space-between",
              flexDirection: isMobile ? "column" : "row",
              flexWrap: "wrap",
              marginBottom: 24,
              backgroundColor: "white",
            }}
          >
            <img
              src={"https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/reserveFirst1.png"}
              alt="camera_1"
              style={{
                borderRadius: 16,
                width: "90%",
                // height: 180,
                background: "#F5F7FA",
              }}
            />
            <ColBox style={{ minWidth: 300, alignItems: "center" }}>
              <br />
              <SegmentedItemTitle style={{ fontSize: isMobile ? 24 : 28 }}>
                루이스코트 서울대입구점
              </SegmentedItemTitle>
              <SegmentedItemText style={{ fontSize: isMobile ? 18 : 24 }}>
                {" "}
                <img src={IconPlace} width={14} style={{ marginTop: 5 }} /> 서울
                관악구 쑥고개로 123 지하 1층
              </SegmentedItemText>
              <SegmentedItemText
                style={{ fontSize: isMobile ? 18 : 24, paddingLeft: 3 }}
              >
                {" "}
                <img src={IconWallet} width={12} /> 2만원 (30분)
              </SegmentedItemText>
              <br />
            </ColBox>
          </RowBox>

          <RowBox
            style={{
              width: "100%",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <ColBox
              style={{
                width: isMobile ? "100%" : undefined,
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3>이름&nbsp;&nbsp;</h3>
                <input
                  className="form-control p-1 mb-2"
                  value={name || ""}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  style={{
                    height: 15,
                    width: 80,
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <h3>전화번호&nbsp;&nbsp;</h3>
                <input
                  className="form-control p-1 mb-2"
                  value={phone || ""}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  style={{
                    height: 15,
                    width: 90,
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                />
              </div>
              <div style={{ display: "flex" }}></div>
            </ColBox>
          </RowBox>

          <RowBox
            style={{
              width: "100%",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <ColBox
              style={{
                width: isMobile ? "100%" : undefined,
                alignItems: isMobile ? "center" : "flex-start",
              }}
            >
              <h2> 예약 날짜 - 2023. 5</h2>
              <table className="calendar">
                <thead>
                  <tr>
                    {daysOfWeek.map((day, index) => (
                      <th style={{ fontSize: 20 }} key={index}>
                        {day}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {calendarRows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td
                          style={{
                            fontSize: 20,
                            cursor:
                              cell && offDays.includes(cell)
                                ? "default"
                                : "pointer",
                            borderRadius: 10,
                            padding: 10,
                          }}
                          onClick={() => {
                            if (!cell || (cell && offDays.includes(cell)))
                              return;
                            clickDay(cell);
                          }}
                          key={cellIndex}
                        >
                          <div
                            style={{
                              fontSize: 20,
                              color:
                                cell && offDays.includes(cell)
                                  ? "lightgray"
                                  : cell && pickedDay == cell
                                  ? "white"
                                  : "green",
                              backgroundColor:
                                cell && pickedDay == cell ? "#03ad8b" : "white",
                              borderRadius: 10,
                              padding: 2,
                            }}
                          >
                            {cell || ""}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </ColBox>
            <div style={{ minWidth: "15%" }} />
          </RowBox>
        </ContentContainer>
      </Section>
      <Section id="section2" style={{}}>
        <ContentContainer>
          <RowBox
            style={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {slots.length > 0 ? (
              <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
                <h2>예약시간</h2>
                <div style={{ display: "flex", flexWrap:'wrap' }}>
                  {slots.map((s: any, idx: number) => {
                    return (
                      <>
                        <div style={{ width: "20%" }}>
                          <div
                            style={{                              
                              marginRight: 10,
                              marginBottom: 10,
                              maxWidth: 200,
                              border: "1px solid black",
                              borderRadius: 10,
                              backgroundColor:
                                s && s == pickedTime ? "#03ad8b" : "white",
                              cursor:
                                s && s == pickedTime ? "default" : "pointer",
                              color: s && s == pickedTime ? "white" : "black",
                            }}
                            onClick={async () => {
                              setPickedTime(s);

                              const logObj = { hash, time:s };
                              await createLog({event:`trainingClickTime`, log: JSON.stringify(logObj)});
                            }}
                          >
                            &nbsp;{s.substr(0, 2) + ":" + s.substr(2)}
                          </div>
                        </div>

                      </>
                    );
                  })}
                </div>
                <ContentContainer>
                  <br />
                  <br />
                  <br />
                  {pickedTime ? (
                    <FilledButton
                      style={{ marginLeft: 24 }}
                      onClick={async () => {
                        if (!name||!phone){
                          Swal.fire({
                            titleText: "오류 발생",
                            text: `이름 혹은 전화번호를 입력해주세요!`,
                            icon: "error",
                            confirmButtonText: "확인",
                          }).then(function(isConfirm) {
                            if (isConfirm) {}
                          });                        
                          // window.scroll({
                          //   top: 10
                          // });
                          return;
                        }

                        let result;
                        try {
                          result = await makeReservation(
                            name,
                            phone,
                            pickedDay,
                            pickedTime
                          );
                        } catch (error) {
                          Swal.fire({
                            titleText: "등록 에러 발생",
                            text: `${error}`,
                            icon: "error",
                            confirmButtonText: "확인",
                          });
                          return;
                        }
                        if (result?.error){
                          Swal.fire({
                            titleText: "등록 에러 발생",
                            text: `${result?.error+' '+result?.message}`,
                            icon: "error",
                            confirmButtonText: "확인",
                          });
                          return;

                        }
                        
                        await createLog({event:'trainingReserve2Click', log: `{'hash':'${hash}'}`});
                        Swal.fire({
                          text: "등록이 완료되었습니다",
                          icon: "success",
                          confirmButtonText: "확인",
                        }).then(function(isConfirm) {
                          if (isConfirm) {
                            navigate("/training");                            
                          }
                        });                        
                      }}
                    >
                      예약하기
                    </FilledButton>
                  ) : null}
                </ContentContainer>
              </Card>
            ) : null}
          </RowBox>
        </ContentContainer>
      </Section>

      {/* footer */}
      <Section style={{ backgroundColor: "#e7e7e7" }}>
        <ContentContainer style={{ paddingTop: 64, paddingBottom: 96 }}>
          <FooterRow>
            <FooterTitle>(주) 테니스베이</FooterTitle>
          </FooterRow>
          <FooterRow style={{ marginTop: 16 }}>
            <FooterContent>
              대표 조기덕 | 사업자등록번호 451-88-02655
            </FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>TEL 070-7537-3135</FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>MAIL contact@springtennis.co</FooterContent>
          </FooterRow>
          <FooterRow style={{ marginTop: 32 }}>
            <ColBox>
              <Caption style={{ marginTop: 8 }}>
                Copyright ⓒ Tennisbay, co., ltd. All Rights Reserved
              </Caption>
            </ColBox>
          </FooterRow>
        </ContentContainer>
      </Section>
    </Page>
  );
};
