import { useEffect, useState, useRef } from "react";
import { useNavigate} from "react-router-dom";
import styled, { css } from "styled-components";
// import { createLog } from "../apis";
 import { createLogNew } from "../apis";
import Logo from "../assets/icon/springtennis_logo.svg";
import "./Ai.css";
async function createLog(log :any) {
    await createLogNew(log);
}

const Page = styled.div`
  width: 100%;
  height: 100%;
  /* background-color: blue; */
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
`;

const Navbar = styled.div<{ colored?: boolean }>`
  position: fixed !important;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 80px;
  padding: 0 20px;
  z-index: 100;
  transition-duration: 0.5s;
  background-color: #ffffff;
  ${({ colored }) => {
    if (colored) {
      return css`
        background-color: rgba(255, 255, 255, 0.24);
        backdrop-filter: blur(12px);
      `;
    }
  }};
`;

const ReserveBtn = styled.div<{ colored?: boolean }>`
  position: fixed !important;
  top: 0;
  right: 10;
  display: flex;
  align-items: right;

  justify-content: center;
  z-index: 100;
  transition-duration: 0.5s;

  padding: 12px;
  border-radius: 4px;
  background-color: #03ad8b;
  cursor: pointer;
  color: #ffffff;
  border: none;

  font-family: Pretendard-SemiBold;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #009174;
  }

  ${({ colored }) => {
    if (colored) {
      return css`
        background-color: rgba(255, 255, 255, 0.24);
        backdrop-filter: blur(12px);
      `;
    }
  }};
`;

const LogoWrapper = styled.div`
  cursor: pointer;
`;

const NavbarContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  align-items: center;
`;

const FilledButton = styled.button`
  padding: 12px;
  border-radius: 4px;
  background-color: #03ad8b;
  cursor: pointer;
  color: #ffffff;
  border: none;

  font-family: Pretendard-SemiBold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  &:hover,
  &:focus {
    background-color: #009174;
  }
`;

const Section1Title = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0px;
  text-align: left;
  color: #141b21;
  margin-bottom: 24px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ColBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 48px;
  border-radius: 24px;
  background-color: #ecf9f6;
  margin: 24px;
`;

const CardTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  margin-bottom: 24px;
`;

const CardSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #46525e;
  margin-bottom: 32px;
`;

const ProductTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 44px;
  line-height: 44px;
  letter-spacing: 0px;
  text-align: center;
  color: #0b9975;
  margin-bottom: 40px;
`;

const ProductSubTitle = styled.span`
  font-family: Pretendard-SemiBold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #141b21;
  margin-bottom: 8px;
`;

const FooterRow = styled.div`
  display: flex;
  margin-left: 20px;
  margin-right: auto;
`;

const FooterTitle = styled.span`
  font-family: Pretendard;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #323334;
`;

const FooterContent = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #4b4d4e;
`;

const Caption = styled.span`
  font-family: Pretendard;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #646768;
`;

const Label = styled.span`
  z-index: 99;
  position: absolute;
  top: 5px;
  left: 5px;
  min-width: 36px;
  padding: 2px;
  height: 30px;
  background-color: whitesmoke;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
`;

const Accordion = ({ title, content, num, hash }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = async () => {
    setIsOpen(!isOpen);
    const logObj = {hash}
    await createLog({event:`trainingToggle${num}Click`, log: JSON.stringify(logObj)});
  };

  return (
    <div className="accordion">
      <div className="accordion-title" onClick={toggleAccordion}>
        {title}
        <span>{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="accordion-content">{content}</div>}
    </div>
  );
};

const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};


export const Test1 = () => {
  function genHash() {
    let randomNumber = Math.floor(Math.random() * 1000000);
    return randomNumber.toString().padStart(6, '0');
  };

  function genSrc(){
    // const params = new URLSearchParams(window.location.pathname);
    // const src = params.get("source");
    const _src = window?.location?.search?.split('=')?.[1];
    return _src;
  }

  const [hash, setHash] = useState(genHash());
  const [src, setSrc] = useState(genSrc());
  
  const [prevScrollY, setPrevScrollY] = useState<number>(0);
  const [sectionY, setSectionY] = useState<any>({
    section1: 0,
    section2: 0,
    section3: 0,
    section4: 0,
  });
  const [showNavbar, setShowNavbar] = useState<boolean>(true);
  const [showBtn, setShowBtn] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const scrollHandler = () => {
    if (window.scrollY > prevScrollY && window.scrollY > 80) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }

    if (window.scrollY > prevScrollY && window.scrollY > 800) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }

    setPrevScrollY(window.scrollY);
    setScrollPosition(window.scrollY);
  };

  const resizeHandler = () => {
    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  const navigate = useNavigate();
  const clickReserveBtn = async()=>{
    const logObj = {hash}
    await createLog({event:'trainingReserve1Click', log: JSON.stringify(logObj)});
    navigate(`/test2`);
  }

  useEffect(() => {
    window.addEventListener("resize", resizeHandler, false);
    window.addEventListener("scroll", scrollHandler, false);

    if (window.scrollY === 0) {
      const section1 = document.getElementById("section1");
      const section2 = document.getElementById("section2");
      const section3 = document.getElementById("section3");
      const section4 = document.getElementById("section4");
      setSectionY({
        section1: section1?.getBoundingClientRect()?.top,
        section2: section2?.getBoundingClientRect()?.top,
        section3: section3?.getBoundingClientRect()?.top,
        section4: section4?.getBoundingClientRect()?.top,
      });
    }

    if (window.visualViewport?.width) {
      if (window.visualViewport?.width < 900) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, prevScrollY]);

  const flagRef = useRef(false);
  const createFirstLog = async () => {
    await createLog({event:'trainingEnter', log: `{'hash':'${hash}', 'src':'${src}'}`})  };

  useEffect(()=>{
    console.log("FIRST LOG IS SENDING...");
    (async ()=>{
      await createFirstLog();
    })();
  }, []);

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  const onPlay = async (videoNumber:number) => {
    console.log(`Video ${videoNumber} started playing`);

    let event='trainingVideo1Click';
    if (videoNumber==2)
    event='trainingVideo2Click';

    const logObj = JSON.stringify({hash});
    await createLog({event, log: logObj});
  };
  useEffect(() => {
    const video1:any = videoRef1.current;
    const video2:any = videoRef2.current;

    if (video1) {
      video1?.addEventListener('play', () => onPlay(1));
    }
    if (video2) {
      video2?.addEventListener('play', () => onPlay(2));
    }

    return () => {
      if (video1) {
        video1.removeEventListener('play', () => onPlay(1));
      }
      if (video2) {
        video2.removeEventListener('play', () => onPlay(2));
      }
    };
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const debouncedScrollPosition = useDebounce(scrollPosition, 200);

  useEffect(() => {
    if (debouncedScrollPosition) {
      console.log("scroll event!")
      const logObj = {hash};

      (async ()=>{
        await createLog({event:`trainingScroll1`, log: JSON.stringify(logObj)});
      })();
  
    }
  }, [debouncedScrollPosition]);


  return (
    <Page>
      <Navbar
        style={{
          display: showNavbar ? "flex" : "none",
          height: isMobile ? 64 : undefined,
        }}
      >
        <NavbarContainer>
          <LogoWrapper>
            {isMobile ? (
              <img
                src={Logo}
                alt="logo"
                style={{ height: 22, objectFit: "cover" }}
              />
            ) : (
              <img src={Logo} alt="logo" />
            )}
          </LogoWrapper>
        </NavbarContainer>
      </Navbar>
      <div style={{ width: "100%", alignContent: "right" }}>
        <ReserveBtn
          style={{
            display: showBtn ? "flex" : "none",
            position: "absolute",
            top: "10px",
            right: "20px",
            justifyContent: "flex-end",
          }}
          onClick={clickReserveBtn}
        >
          예약하러가기
        </ReserveBtn>
      </div>

      <Section
        id="section2"
        style={{
          marginTop: isMobile ? 56 : 96,
          marginBottom: isMobile ? 56 : 96,
        }}
      >
        <ContentContainer>
          <RowBox
            style={{
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <CardTitle style={{fontSize:25}}>
                월 8만원으로 테니스 실력
                <br />
                늘려드려요!
              </CardTitle>

              <CardSubTitle style={{ fontSize: 15 }}>
                볼머신 연습을 하며 원포인트 교정을 받아보세요.
              </CardSubTitle>

              <video width="100%" controls ref={videoRef2}
              poster={"https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/examPos3.jpg"}>
                <source
                  src="https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/coachingExam3.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <CardSubTitle style={{ fontSize: 15 }}>
                나의 폼을 확인하며 받는 디테일한 영상 코칭
              </CardSubTitle>

              <video width="100%" controls ref={videoRef1} 
              poster={"https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/examPos1.jpg"}>
                <source 
                  src="https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/coachingExam1.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <CardSubTitle style={{ fontSize: 15 }}>
                샷 별로 주어지는 실시간 피드백
              </CardSubTitle>

            </Card>


            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <CardTitle style={{fontSize:25}}>
                30분에 2만원, 실력향상을
                <br />
                위한 가장 효율적 수단
              </CardTitle>
              <img src={"https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/price.png"}
               alt="book" style={{ width: "100%" }} />
            </Card>

            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <CardTitle style={{fontSize:25}}>
                그냥 볼머신이 아닙니다.
                <br />
                레슨입니다.
              </CardTitle>
              <img src={"https://springtennis.s3.ap-northeast-2.amazonaws.com/feedback/temp/comment.png"}
               alt="book" style={{ width: "100%" }} />
            </Card>



            <Card style={{ minWidth: isMobile ? "80%" : undefined }}>
              <CardTitle>자주 묻는 질문</CardTitle>
              <div>
                <Accordion
                  hash={hash}
                  num={1}
                  title="▶ 피드백은 어떤 형태로 몇번 제공되나요?"
                  content=" 음성 코멘트는 샷마다 실시간으로 제공되고, 연습 영상에 대한 피드백은 이용후 10분 / 20분이 경과하였을 때 총 두 번 제공됩니다."
                />
                <Accordion
                  hash={hash}
                  num={2}
                  title="▶ 연습 공간 크기가 어떻게 되나요?"
                  content=" 연습 코트는 가로 6m x 세로 15m x 층고 3m의 레슨용 반코트 규격입니다."
                />
                <Accordion
                  hash={hash}
                  num={3}
                  title="▶ 이용 가격은 어떻게 되나요?"
                  content="이용 가격은 30분에 2만원입니다."
                />
                <Accordion
                  hash={hash} 
                  num={4}
                  title="▶ 주차 가능한가요?"
                  content="건물 기계식 주차장에 주차하시면 됩니다. (방문차량 1시간 무료)"
                />

              </div>
            </Card>
          </RowBox>
        </ContentContainer>
      </Section>
      <Section id="section3">
        <ContentContainer>
          <ProductTitle>
            지금 경험해보세요!
          </ProductTitle>
          <FilledButton
                style={{ marginLeft: 24 }}
                onClick={clickReserveBtn}
                >
                예약하러 가기
              </FilledButton>

        </ContentContainer>
      </Section>
      <Section
        id="section4"
        style={{
          backgroundColor: "white",
          marginTop: 0,
          paddingTop: isMobile ? 32 : 64,
          paddingBottom: isMobile ? 32 : 64,
        }}
      >

      </Section>
      {/* footer */}
      <Section style={{ backgroundColor: "#e7e7e7" }}>
        <ContentContainer style={{ paddingTop: 64, paddingBottom: 96 }}>
          <FooterRow>
            <FooterTitle>(주) 테니스베이</FooterTitle>
          </FooterRow>
          <FooterRow style={{ marginTop: 16 }}>
            <FooterContent>
              대표 조기덕 | 사업자등록번호 451-88-02655
            </FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>TEL 070-7537-3135</FooterContent>
          </FooterRow>
          <FooterRow>
            <FooterContent>MAIL contact@springtennis.co</FooterContent>
          </FooterRow>
          <FooterRow style={{ marginTop: 32 }}>
            <ColBox>
              <Caption style={{ marginTop: 8 }}>
                Copyright ⓒ Tennisbay, co., ltd. All Rights Reserved
              </Caption>
            </ColBox>
          </FooterRow>
        </ContentContainer>
      </Section>
    </Page>
  );
};
