import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Ai } from "../pages/Ai";
import { Reserve } from "../pages/Reserve";
import { Test1 } from "../pages/Test1";
import { Test2 } from "../pages/Test2";

export const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/training" element={<Ai />} />
        <Route path="/reserve" element={<Reserve />} />
        <Route path="/test1" element={<Test1 />} />
        <Route path="/test2" element={<Test2 />} />
      </Routes>
    </BrowserRouter>
  );
};
